// Imports
import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

const router = new Router({
  mode: "hash",
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash };
    if (savedPosition) return savedPosition;

    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      component: () => import("@/layouts/home/Index.vue"),
      children: [
        {
          path: "",
          name: "Start",
          component: () => import("@/views/home/Index.vue"),
          meta: { breadCrumb: "start" },
        },
        {
          path: "about",
          name: "Über uns",
          component: () => import("@/views/about/Index.vue"),
          meta: {
            src: require("@/assets/aboutus_hero.jpg"),
            breadCrumb: "about",
          },
        },
        {
          path: "projects",
          name: "Projekte",
          component: () => import("@/views/projects/Index.vue"),
          meta: {
            src: require("@/assets/projects_hero.jpg"),
            breadCrumb: "projects",
          },
        },
        {
          path: "projects/:project",
          name: "Projekt",
          component: () => import("@/views/projects/Project.vue"),
          meta: {
            src: require("@/assets/projects_hero.jpg"),
            breadCrumb: "project",
          },
        },
        {
          path: "contact-us",
          name: "Kontakt",
          component: () => import("@/views/contact-us/Index.vue"),
          meta: {
            src: require("@/assets/contact_hero.jpg"),
            breadCrumb: "contact-us",
          },
        },
        {
          path: "imprint",
          name: "Imprint",
          component: () => import("@/views/imprint/Index.vue"),
        },
        {
          path: "*",
          name: "FourOhFour",
          component: () => import("@/views/404/Index.vue"),
        },
      ],
    },
  ],
});

export default router;
