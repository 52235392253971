<template>
  <v-theme-provider :dark="dark">
    <div v-if="!isMobile">
      <v-row>
        <v-col align-self="center">
          <v-row style="display: flex; justify-content: center">
            <base-img
              :src="require(`@/assets/logo_dark.svg`)"
              contain
              max-width="150"
              width="100%"
            />
          </v-row>
          <v-row style="justify-content: center">
            <base-img
              :src="require(`@/assets/text-logo-dark.svg`)"
              contain
              max-width="150"
              width="100%"
            />
          </v-row>
        </v-col>
        <v-col>
          <div>
            <base-info-card :title="title" color="primary">
              <slot />
            </base-info-card>

            <template v-for="({ icon, text, title: t }, i) in business">
              <base-avatar-card
                :key="i"
                :icon="icon"
                :outlined="false"
                :title="t"
                horizontal
                space="0"
              >
                <!-- Do not use v-html for user -->
                <!-- provided values -->
                <div v-html="text" />
              </base-avatar-card>

              <v-divider
                v-if="i + 1 !== business.length"
                :key="`divider-${i}`"
                class="my-2"
              />
            </template>

            <v-divider class="my-2" />

            <base-avatar-card
              icon="mdi-email"
              :outlined="false"
              color="transparent"
              horizontal
              space="0"
            >
              <!-- Do not use v-html for user -->
              <!-- provided values -->
              <base-btn
                :block="$vuetify.breakpoint.smAndDown"
                color="white"
                small
                outlined
                target="_blank"
                data-name="mail"
                data-domain="lit-labs"
                data-tld="de"
                onclick="window.location.href = 'mailto:' + this.dataset.name + '@' +
this.dataset.domain + '.' + this.dataset.tld + '?subject=Ich%20will%20mehr'; return false;"
              >
                Email
              </base-btn>
            </base-avatar-card>
          </div>
        </v-col>
      </v-row>
    </div>
    <div v-else>
      <base-info-card :title="title" color="primary">
        <slot />
      </base-info-card>

      <template v-for="({ icon, text, title: t }, i) in business">
        <base-avatar-card
          :key="i"
          :icon="icon"
          :outlined="false"
          :title="t"
          color="transparent"
          horizontal
          space="0"
        >
          <!-- Do not use v-html for user -->
          <!-- provided values -->
          <div v-html="text" />
        </base-avatar-card>

        <v-divider
          v-if="i + 1 !== business.length"
          :key="`divider-${i}`"
          class="my-2"
        />
      </template>

      <v-divider class="my-2" />

      <base-avatar-card
        icon="mdi-email"
        :outlined="false"
        color="transparent"
        horizontal
        space="0"
      >
        <!-- Do not use v-html for user -->
        <!-- provided values -->
        <base-btn
          :block="$vuetify.breakpoint.smAndDown"
          color="white"
          small
          outlined
          target="_blank"
          data-name="mail"
          data-domain="lit-labs"
          data-tld="de"
          onclick="window.location.href = 'mailto:' + this.dataset.name + '@' +
this.dataset.domain + '.' + this.dataset.tld + '?subject=Ich%20will%20mehr'; return false;"
        >
          Email
        </base-btn>
      </base-avatar-card>
    </div>
  </v-theme-provider>
</template>

<script>
export default {
  name: "BaseInfo",

  props: {
    title: String,
    dark: Boolean,

    mobileBreakpoint: {
      type: [Number, String],
      default: 768,
    },
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.width <= this.mobileBreakpoint;
    },
  },
  data: () => ({
    business: [
      {
        icon: "mdi-map-marker-outline",
        title: "Adresse",
        text: "LIT labs UG (haftungsbeschränkt)<br>Siebachstr. 101<br>50733 Köln",
      },
      {
        icon: "mdi-cellphone",
        title: "Telefon",
        text: "0221 - 204 75 113",
      },
    ],
  }),
};
</script>

<style scoped>
.cryptedmail {
  content: attr(data-name) "@" attr(data-domain) "." attr(data-tld);
}
</style>
