import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    count: 0,

    features: [
      {
        title: "Software-Entwicklung",
        icon: "mdi-code-braces",
        text: "Wir entwickeln innovative und effiziente Software-Lösungen – clevere Scripte, kraftvolle Parallel-Computing-Anwendungen und intuitive Web-Anwendungen. Handgemachte Software aus Köln.",
      },
      {
        title: "Interaktive Medien",
        icon: "mdi-gesture-tap-button",
        text: "Wir hauchen interaktiven Installationen und Exponaten Leben ein - für Museen, Ausstellungen und Messen. Gerne übernehmen wir dabei auch UX-Konzeption und Interface Design.",
      },
      {
        title: "(3D) Visualisierung",
        icon: "mdi-monitor-screenshot",
        text: "Wir schaffen beeindruckende Welten – butterweiche Animationen, Millionen von Fragmenten, knackscharfe Videos und dreidimensionale Objekte.",
      },
      {
        title: "Medientechnik",
        icon: "mdi-projector",
        text: "Wir finden für jede Anwendung die richtige Technik - für Bild, Ton und Licht, in Groß und Klein. Falls die passenden Geräte nicht existieren, werden sie von uns fachgerecht gebaut.",
      },

      {
        title: "Projektleitung",
        icon: "mdi-clipboard-check-outline",
        text: "Wir betreuen Projekte mit größter Sorgfalt – und sprechen dabei die Sprache der Kreativen genauso wie die der technischen Experten.",
      },
      {
        title: "Postproduktion Supervision",
        icon: "mdi-movie-open-star-outline",
        text: "Wir sorgen für reibungslose Abläufe in der Postproduktion. Egal ob Blockbuster, Streaming-Serie oder Arthouse-Film. Durch ein weitreichendes, internationales Netzwerk finden wir immer die passende Lösung.",
      },
    ],
    categories: [
      "All",
      "Post-Produktion",
      "Forschung und Entwicklung",
      "Hackdays",
      "Software",
      "Digital Arts",
      "Museum",
      "Interaktive Medien",
    ],
    projects: [
      {
        slug: "photon",
        title: "Photon - 3D Colorgrading",
        tags: [0, 1, 2, 4],
        cardPreview: "projects_photon.jpg",
        media: [
          "https://uploads-ssl.webflow.com/5edecd99cc96647b458d954e/6103ded40eac2160191e658c_photon_ui_isotomin_01-transcode.mp4",
        ],
        heading: "A unique 3D-Colorgrading experience",
        text: "Photon ist unsere Origin-Story. In Zusammenarbeit mit Colorist Moritz Peters und Color.io haben wir ein einzigartiges Werkzeug zur Farbbearbeitung von digitalen Bildern und Videos entwickelt. Mit Photon ist Color Grading einfach und intuitiv. Durch eine interaktive 3D-Visualisierung der Farben werden Grenzen aufgehoben und jeder Schritt anschaulich wie nie zuvor. Ein OpenFX Plugin für Resolve ermöglicht die Integration in existierende Workflows, ein Glättungstool sorgt für weiche Farbverläufe und der LUT-Import/Export für universelle Weiterverwendung der Ergebnisse.",
        info: [
          ["WEBSEITE", "https://color.io/photon"],
          ["ENTWICKLUNGSZEIT", "2 Jahre"],
          ["TECHSTACK", "C++, OpenGL, OpenCL"],
        ],
      },
      {
        slug: "impfbruecke",
        title: "Impfbrücke",
        tags: [0, 3, 4],
        imgs: [],
        cardPreview: "projects_impfbruecke.jpg",
        media: ["https://content.lit-labs.de/assets/impfbruecke16_9.mp4"],
        heading: "Impfbrücke -  Die automatisierte Vergabe von Restimpfstoffen",
        text: "Die Corona-Pandemie hat die ganze Welt auf den Kopf gestellt. Auch uns bei LIT labs hat dieses Thema sehr beschäftigt. Als Anfang 2021 die ersten Impfungen starteten, fiel uns schnell auf, dass eine Möglichkeit zur schnellstmöglichen Vergabe von Rest-Impfstoffen fehlte – einmal aufgetaut ist der Impfstoff nur begrenzt haltbar und oft wurden Termine kurzfristig abgesagt oder erst gar nicht wahrgenommen. Kurzerhand stellten wir ein Team zusammen, das innerhalb von 2 Wochen einen funktionsfähigen Prototyp für ein interessiertes Impfzentrum entwickelte, welcher kurzfristige Impftermine per SMS-Nachricht verteilt: Die „Impfbrücke“ war geboren. Dank der großzügigen Unterstützung durch unsere Freunde von BlackMars Capital konnten wir die Entwicklung weiter vorantreiben, die Anwendung zum Selbstkostenpreis anbieten und wurden bald in 43 Impfzentren bundesweit eingesetzt. Im Laufe des Jahres 2021 konnten wir über 41.000 Impfdosen vor der Entsorgung retten und an Impfwillige vermitteln.",
        info: [
          ["WEBSEITE", "https://impfbruecke.de"],
          ["ENTWICKLUNGSZEIT", "2 Wochen"],
          ["VERMITTELTE IMPFTERMINE", "42135"],
          ["UNTERSTÜTZTE IMPFZENTREN", "43"],
          ["TECHSTACK", "GO, JavaScript, PHP, MYSQL, Docker, ansible, DevOps"],
        ],
      },
      {
        slug: "dsd",
        title: "Deutsche Stiftung Denkmalschutz",
        tags: [0, 4, 6],
        cardPreview: "projects_DSD_1.png",
        media: ["projects_DSD_1.png"],
        heading:
          "Technische Umsetzung der Userinterfaces - Wanderaustellung zum Thema Denkmalschutz",
        text: "Seit September 2021 tourt die Wanderausstellung „Liebe oder Last?! Baustelle Denkmal“ der Deutschen Stiftung Denkmalschutz durch Deutschland. Sie zeigt anschaulich und interaktiv, was es bedeutet Denkmäler zu erhalten und zu pflegen. Insgesamt sechs Stationen präsentieren das Thema dabei aus ganz unterschiedlichen Blickwinkeln. Wir von LIT labs durften die softwaretechnische Umsetzung dieser Ausstellung beisteuern. Dabei wurde besonderer Wert auf robuste Implementierung und einfache Handhabung gelegt. Die Vielfalt der Exponate erforderte außerdem sehr individuelle Lösungen auf Software-Ebene: Eine digitale Fotobox mit Greenscreen-Funktion und eMail-Versand ist genauso vertreten wie ein digitales Gästebuch mit Umfrage-Tool, ein digitales Fernrohr, ein Spreng-Simulator und viele weitere haptische Interaktionsmöglichkeiten, um die digitalen Inhalten erlebbar zu machen.",
        info: [
          ["TECHSTACK", "c++"],
          ["DEVELOPMENT TIME", "2 MONTHS"],
        ],
      },
      {
        slug: "eyes",
        title: "Eyes",
        tags: [0, 3, 5, 7],
        cardPreview: "projects_eyes_1.jpg",
        heading: "EYES - Einfach, Elegant und Faszinierened",
        media: [
          "projects_eyes_1.jpg",
          "projects_eyes_2.jpg",
          "projects_eyes_3.jpg",
          "projects_eyes_4.jpg",
        ],
        text: "eyes ist eine interaktive Lichtinstallation. Mittels einer hochauflösenden Kamera kann der Betrachter sein Auge auf der Leinwand LIVE betrachten. Durch eine zeitliche Verzögerung der Darstellung der Augen auf den einzelnen Projektionsflächen entsteht ein surrealer Bezug. Eyes besticht durch seine Einfachheit und die Schönheit der gezeigten Augen. Diese Installation lädt Groß und Klein zum Spielen und kreativen Ausprobieren ein.",
        info: [
          [
            "CREATORS",
            "Janno Ströcker, Moritz Ermen-Bausch, Manuel Hüttel und Moritz Kleine-Büning",
          ],
          [
            "TOURDATES",
            "Nightlite Festival, Odonien 2012<br>Zugvögel Festival, Eifel 2012<br>Waking Life, Portugal 2012<br> Niehler Freiheit, Köln 2013",
          ],
          [
            "Kurzinfo",
            "Faszieniernde Lichtinstallation welche mittels bestechender Einfachheit Raum und Zeit auflöst.",
          ],
        ],
      },
      {
        slug: "eyesscattered",
        title: "Eyes Scattered",
        tags: [0, 3, 5, 7],
        heading: "EYES SCATTERED - für 3 Monate in der Karlsruher Innenstadt",
        cardPreview: "projects_eyes_scattered.jpg",
        media: ["projects_eyes_scattered.jpg"],
        text: 'eyes scattered ist ein Cousin der "eyes"-Installation. Die Tourist-Information Karlsruhe lud uns ein, ihren Beitrag zum Light Festival SCHLOSSLICHTSPIELE zu kreieren. In bester Lage, direkt am Marktplatz in der Karlsruher Innenstadt, durften wir unsere "eyes"-Installation für 3 Monate präsentieren. Es handelt sich dabei um einen abgewandelten, Corona-konformen Verwandten: Im Gegensatz zum Original wurde bei "eyes scattered" auf einen direkten Kontakt mit dem Interface verzichtet. Stattdessen wird mittels Gesichtserkennung und einer etwas weiter entfernten Kamera das Gesicht des Betrachters in seine Einzelteile zerschnitten und auf mehreren Projektionsflächen zeitlich verzögert dargestellt.',
        info: [
          ["CREATORS", "Janno Ströcker, Moritz Ermen-Bausch, Manuel Hüttel"],
          ["TOURDATES", "Tourist-Information Karlsruhe, 2021"],
          [
            "Kurzinfo",
            'Corona-konforme "eyes"-Variante, welche 3 Monate in der Karlsruher Fußgängerzone ausgestellt wurde.',
          ],
        ],
      },
      {
        slug: "ferro",
        title: "ferro-effektor",
        cardPreview: "projects_ferro_1.jpg",
        media: [
          "https://content.lit-labs.de/assets/ferro_3.mp4",
          "https://content.lit-labs.de/assets/ferro_4.mp4",
          "projects_ferro_3.jpg",
          "projects_ferro_2.jpg",
        ],
        tags: [0, 3, 5, 7],
        heading: "FERRO-EFFEKTOR",
        text: "Ferrofluid ist eine faszinierende Flüssigkeit: Hält man einen Magneten in ihre Nähe, wird diese Flüssigkeit magisch angezogen. Diese Eigenschaft haben wir uns zunutze gemacht, um ein einzigartiges Effektgerät zu kreieren. Mittels eines Elektromagneten gelingt es uns, diese Flüssigkeit für den Betrachter unsichtbar zum Leben zu erwecken. Es wirkt als hätte sie ein Eigenleben - oder wird sie doch von einer unsichtbaren Kraft gesteuert? Der Ferro-Effektor reagiert auf seine Umgebung und kann mit allen nur denkbaren Daten gefüttert werden, um zum Tanzen gebracht werden.",
        info: [
          ["CREATORS", "Janno Ströcker, Moritz Ermen-Bausch, Manuel Hüttel"],
          [
            "TOURDATES",
            "Hoflärm Festival, Westerwald 2021<br>Zugvögel Festival, Eifel 2021",
          ],
          ["Kurzinfo", "Welterste lebende, interaktive Flüssigkeit."],
        ],
      },
      {
        slug: "holodance",
        title: "Holodance",
        cardPreview: "projects_holodance.jpg",
        media: [
          "https://content.lit-labs.de/assets/holodance.mp4",
          "projects_holodance.jpg",
        ],
        heading: "Holodance - So nah und doch so fern",
        tags: [0, 3, 5, 7],
        text: "Bei Holodance handelt es sich um eine Hologramm-Projektion: Die Betrachter betreten nichtsahnend eine der Bühnen und sehen sich selbst urplötzlich in Lebensgröße im Wald stehen. Die Bühnen sind bewusst voneinander getrennt, sodass sich die Tanzenden nur als Hologramme im Wald begegnen können. Skurrile Interaktionen zwischen den Tanzenden entstehen. Holodance lädt die Betrachter ein, selbst ein Stück der Installation zu werden und wahnwitzige Theaterstücke darzubieten, welche genauso schnell wieder verschwinden, wie sie entstanden sind.",
        info: [
          ["CREATORS", "Manuel Hüttel"],
          ["TOURDATES", "Zugvögel Festival, Eifel 2021"],
          [
            "Kurzinfo",
            "Tanze digital neben deinen Liebsten. Holodance bringt Menschen zusammen, obwohl sie voneinander entfernt sind.",
          ],
        ],
      },
    ],
  },
  mutations: {
    increment(state) {
      state.count++;
    },
  },
});
