// Vuetify Documentation https://vuetifyjs.com
import '@mdi/font/css/materialdesignicons.css'
import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: true,
    themes: {
      light: {
        primary: "#ffffff",
        secondary: "#bbbbbb",
        accent: "#0a0a0a",
        textAccent: "#444444",
      },
      dark: {
        primary: "#444444",
        secondary: "#111111",
        accent: "#939393",
        textAccent: "#ffffff",
      },
    },
  },
});
