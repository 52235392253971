import Vue from 'vue'

import App from './App.vue'
import router from './router'
import store from './plugins/vuex'

import vuetify from './plugins/vuetify'
import './plugins'
import 'video.js/dist/video-js.css'

Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  store,
  render: (h) => h(App),
}).$mount('#app')
